import InscriptionTemplate from "../components/InscriptionTemplate";

function Inscription() {

    const camping = {
        title: 'registration.camping.title',
        information_title: 'registration.camping.rules',
        information: [
            "registration.camping.rule1",
            "registration.camping.rule2",
            "registration.camping.rule3",
            "registration.camping.rule4",
            "registration.camping.rule5",
            { text: "registration.camping.seePrices", lien: '/fees' },
        ],
        mail_info: {
            title: 'registration.mail.title_onsite',
            info: 'registration.mail.info',
            sendTo: 'registration.mail.send_mail'
        },
        pdf: 'Inscription_camping_2025.pdf',
        pdf_image_src: '/images/Inscription_camping_2025.jpg',
        pdf_image_alt: "PDF d'inscription pour le camping",
        online: true,
    };

    const kiosque = {
        title: 'registration.kiosque.title',
        information: [],
        mail_info: {
            title: 'registration.mail.title_email',
            info: 'registration.mail.info',
            sendTo: 'registration.mail.send_email'
        },
        pdf: 'Inscription-Kiosque-2025.pdf',
        pdf_image_src: '/images/inscription-kiosque-2025.jpg',
        pdf_image_alt: "PDF d'inscription pour les kiosques",
        online: false,
    };

    const truck = {
        title: 'registration.truck.title',
        information_title: 'registration.truck.information',
        information: [
            "registration.truck.info1",
            { text: "registration.truck.info2", note: "registration.truck.info2note" },
            { text: "registration.truck.seePrices", lien: '/fees' },
        ],
        mail_info: {
            title: 'registration.mail.title_onsite',
            info: 'registration.mail.info',
            sendTo: 'registration.mail.send_mail'
        },
        pdf: 'Inscription_camions_2025.pdf',
        pdf_image_src: '/images/Inscription_camions_2025.jpg',
        pdf_image_alt: "PDF d'inscription camions",
        online: true,
    };

    const sponsors = {
        title: 'registration.sponsors.title',
        information: [],
        mail_info: {
            title: 'registration.mail.title_email_onsite',
            info: 'registration.mail.info',
            sendTo: 'registration.mail.send_mail_or_email'
        },
        pdf: 'Demande_commandite_2025.pdf',
        pdf_image_src: '/images/Demande_commandite_2025.jpg',
        pdf_image_alt: "PDF de demande de commandite",
        online: true,
    };

    return (
        <>
            <InscriptionTemplate item={truck} />
            <InscriptionTemplate item={camping} />
            <InscriptionTemplate item={sponsors} />
            <InscriptionTemplate item={kiosque} />
        </>
    );
}

export default Inscription;
