import {useState} from "react";

function CustomImage({ image, handleOnClick }) {
    const [isHovered, setIsHovered] = useState(false);
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const containerStyle = {
        position: "relative",
        width: "100%",
        paddingTop: "75%",
    };

    const imageStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
        transition: "transform 0.3s ease-in-out",
        transform: isHovered ? "scale(1.05)" : "scale(1)",
    };

    return (
        <div style={containerStyle}>
            <img
                src={image.src}
                alt={image.alt}
                style={imageStyle}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleOnClick}
            />
        </div>
    );
}

export default CustomImage;
