import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { Col, Row } from "react-bootstrap";
import ButtonxPayrience from "./ButtonxPayrience";
import { Link } from "react-router-dom";

function InscriptionTemplate({ item }) {
    const { t } = useTranslation();

    const pdfContainer = {
        border: '4px solid black',
        width: '100%',
        margin: '0 auto'
    };

    const pdfImg = {
        width: '100%',
        height: 'auto',
        display: 'block',
    };

    console.log(item.information)

    // noinspection JSCheckFunctionSignatures
    return (
        <Container className="pt-3">
            <Container>
                <Row className='p-3 justify-content-center'>
                    {item.online ? (
                        <>
                            <h1 className="fw-bolder">{t(item.title)}</h1>
                            <Col md={6} className="pb-4">
                                <h3>{t("registration.online.title")}</h3>
                                <ButtonxPayrience text={"registration.button"} />
                                <h3 className="pt-4">{t(item.information_title)}</h3>
                                <ul style={{ fontSize: '19px' }}>
                                    {item.information.map((info, index) => (
                                        <li
                                            key={index}
                                            className={`py-1`}
                                            style={typeof info === 'object' && info.lien ? { listStyleType: 'none' } : {}}
                                        >
                                            {info.note ? (
                                                <p>{t(info.text)}<span className="text-danger">{t(info.note)}</span></p>
                                            ) : info.lien ? (
                                                <Link className="text-danger" to={info.lien}>{t(info.text)}</Link>
                                            ) : (
                                                t(info)
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </Col>
                            <Col md={6}>
                                <h3>{t(item.mail_info.title)}</h3>
                                <p>
                                    {t(item.mail_info.info)}
                                    <a href={item.pdf}
                                        download={item.pdf}
                                        className="text-danger">
                                        {t("registration.mail.clickHere")}
                                    </a>
                                    {t(item.mail_info.sendTo)}
                                </p>
                                <div style={pdfContainer}>
                                    <a href={item.pdf} download={item.pdf}>
                                        <img
                                            alt={item.pdf_image_alt}
                                            src={item.pdf_image_src}
                                            style={pdfImg}
                                        />
                                    </a>
                                </div>
                            </Col>
                        </>
                    ) : (
                        <Col md={6}>
                            <h1 className="fw-bolder">{t(item.title)}</h1>
                            <div>
                                <h3>{t(item.mail_info.title)}</h3>
                                <p>
                                    {t(item.mail_info.info)}
                                    <a href={item.pdf}
                                        download={item.pdf}
                                        className="text-danger">
                                        {t("registration.mail.clickHere")}
                                    </a>
                                    {t(item.mail_info.sendTo)}
                                </p>
                                <div style={pdfContainer}>
                                    <a href={item.pdf} download={item.pdf}>
                                        <img
                                            alt={item.pdf_image_alt}
                                            src={item.pdf_image_src}
                                            style={pdfImg}
                                        />
                                    </a>
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </Container>
        </Container>
    );
}

export default InscriptionTemplate;
