import React from "react";
import Container from "react-bootstrap/Container";

function FacebookPageWindow() {
    return (
        <Container className="d-flex justify-content-center align-items-center" style={{ height: '500px' }}>
            <iframe
                title="Facebook Page Plugin"
                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Ftrucknrollencoeur%2F&amp;tabs=timeline&amp;width=390&amp;height=500&amp;small_header=false&amp;adapt_container_width=true&amp;hide_cover=false&amp;show_facepile=true&amp;appId=264029867010107"
                style={{ border: 'none', overflow: 'hidden', width: '100%', height: '100%' }}
                allow="encrypted-media"
            />
        </Container>
    );
}

export default FacebookPageWindow;
