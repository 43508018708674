import {useTranslation} from "react-i18next";
import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import CustomImage from "./CustomImage";
import {Link, useNavigate} from "react-router-dom";

function SideBySidePicturesKids({ image1, image2 }) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <Container>
            <Row className="justify-content-center">
                <Col xs={12} md={6} className="text-center mb-3">
                    {/* TODO: REMETTRE ET CHANGER LE TITRE AU PLURIEL ()=> navigate("/more/1")*/}
                    <CustomImage image={image1} />
                    <ul style={{listStyleType: 'none', paddingLeft: '0px'}} className="text-lg-start">
                        <li className="text-uppercase py-2 fw-bold" style={{fontSize: '18px'}}>
                            {image1.alt}
                        </li>
                        <li>{image1.age}{t("home.starKids.yearsOld")}</li>
                        {/*<li>{image1.ville}</li>*/}
                        {/*<li><Link className='text-danger text-decoration-none' to={"/more/1"}>{t("home.starKids.knowMore")}</Link></li>*/}
                    </ul>
                </Col>
                {/*<Col xs={12} md={6} className="text-center mb-3">*/}
                {/*    <CustomImage image={image2} handleOnClick={()=> navigate("/more/2")}/>*/}
                {/*    <ul style={{listStyleType: 'none', paddingLeft: '0px'}} className="text-lg-start">*/}
                {/*        <li className="text-uppercase pb-1 pt-2 fw-bold" style={{fontSize: '18px'}}>*/}
                {/*            {image2.alt}*/}
                {/*        </li>*/}
                {/*        <li>{image2.age}{t("home.starKids.yearsOld")}</li>*/}
                {/*        <li>{image2.ville}</li>*/}
                {/*        <li><Link className='text-danger text-decoration-none' to={"/more/2"}>{t("home.starKids.knowMore")}</Link></li>*/}
                {/*    </ul>*/}
                {/*</Col>*/}
            </Row>
        </Container>
    );
}


export default SideBySidePicturesKids;
