import {useState} from "react";
import {useTranslation} from "react-i18next";
import i18n from "i18next";

function ButtonxPayrience({ text }) {
    const {t} = useTranslation()
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    const handleClick = () => {
        window.location.href = "https://secure3.xpayrience.com/festival_trucknroll_en_coeur";
    };

    const spanStyle = {
        display: 'inline-block',
        color: 'white',
        fontSize: '18px',
        backgroundColor: isHovered ? 'red' : 'black',
        transition: 'background-color 0.3s, transform 0.3s',
        transform: isHovered ? 'scale(1.05)' : 'scale(1)',
        padding: '20px 30px',
        cursor: 'pointer',
        borderRadius: '5px',
        textTransform: 'uppercase',
    };

    const is_english = i18n.language === 'en';
    const url = is_english ? "https://secure3.xpayrience.com/festival_trucknroll_en_coeur_en" : "https://secure3.xpayrience.com/festival_trucknroll_en_coeur";

    return (
        <a href={url} onClick={handleClick}>
            <span style={spanStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
                {t(text)}
            </span>
        </a>
    );
}

export default ButtonxPayrience;
