function Header() {
    return (
        <div style={{ position: 'relative', textAlign: 'center' }}>
            <img
                src="/images/banderole_2025.jpeg"
                alt="Background"
                style={{ maxWidth: '100%', minWidth: '100%', height: '100%', minHeight: '75px', maxHeight: '170px', objectFit: 'cover' }}
            />
        </div>
    );
}

export default Header;
