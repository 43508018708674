import {useTranslation} from "react-i18next";
import { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";

function Dates() {
    const { t } = useTranslation();
    const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 992);

    useEffect(() => {
        const handleResize = () => setIsLargeScreen(window.innerWidth > 992);
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return (
        <Container
            fluid
            className="sticky-top py-2"
            style={{
                backgroundColor: "#050505",
                color: "rgba(255, 255, 255, 0.55)",
                top: "95px",
            }}
        >
            <div className="container-lg mx-auto">
                {!isLargeScreen ? (
                    <p className="text-center p-0 mb-2 fs-5">{t("date.single")}</p>
                ) : (
                    <div className="d-flex justify-content-between px-3">
                        <p className="p-0 mb-2 fs-4">{t("date.1")}</p>
                        <p className="p-0 mb-2 fs-4">{t("date.2")}</p>
                        <p className="p-0 mb-2 fs-4">{t("date.3")}</p>
                    </div>
                )}
            </div>
        </Container>
    );
}

export default Dates;
