import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useState } from 'react';
import { Button } from "react-bootstrap";

function NavBar() {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const { i18n } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(i18n.language);

    const toggleLanguage = () => {
        const newLanguage = currentLanguage === 'en' ? 'fr' : 'en';
        i18n.changeLanguage(newLanguage).then(() => {
            setCurrentLanguage(newLanguage);
            console.log(`Switched language to ${newLanguage}`);
            setExpanded(false);
        });
    };

    const handleNavLinkClick = () => {
        setExpanded(false);
    };

    return (
        <Navbar expand="xxl" expanded={expanded} className="sticky-top justify-content-center" style={{ backgroundColor: '#050505' }} variant="dark">
            <Container className="d-flex align-items-center" style={{ margin: '0px' }}>
                <Navbar.Brand as={NavLink} to="/">
                    <img
                        src="/images/festival-truck-n-roll-brand.jpg"
                        style={{ minHeight: '30px', minWidth: '150px', maxHeight: '100px', maxWidth: '295px' }}
                        className="d-inline-block"
                        alt={t("name")} />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ms-auto" style={{ fontSize: '20px' }}>
                        <Nav.Link id={'nav-link'} as={NavLink} to="" onClick={handleNavLinkClick}>{t("home.label")} </Nav.Link>
                        <Nav.Link id={'nav-link'} as={NavLink} to="registration" onClick={handleNavLinkClick}> {t("registration.label")} </Nav.Link>
                        <Nav.Link id={'nav-link'} as={NavLink} to="schedule" onClick={handleNavLinkClick}> {t("schedule.label")} </Nav.Link>
                        <Nav.Link id={'nav-link'} as={NavLink} to="fees" onClick={handleNavLinkClick}> {t("fees.label")} </Nav.Link>
                        <Nav.Link id={'nav-link'} as={NavLink} to="rules" onClick={handleNavLinkClick}> {t("rules.label")} </Nav.Link>
                        <Nav.Link id={'nav-link'} as={NavLink} to="sponsors" onClick={handleNavLinkClick}> {t("sponsors.label")} </Nav.Link>
                        {/*<Nav.Link id={'nav-link'} as={NavLink} to="indications" onClick={handleNavLinkClick}> {t("indications.label")} </Nav.Link>*/}
                        <Nav.Link id={'nav-link'} as={NavLink} to="contactUs" onClick={handleNavLinkClick}> {t("contactUs.label")} </Nav.Link>
                        <Button style={{ textAlign: 'left' }} variant='dark' id={'button-language'} onClick={toggleLanguage}>
                            {currentLanguage === 'en' ? 'Français' : 'English'}
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default NavBar;
