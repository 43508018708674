import Container from "react-bootstrap/Container";
import * as emailjs from "emailjs-com";
import {useState} from "react";
import {Button, Col, Form, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faSnapchat, faTiktok } from '@fortawesome/free-brands-svg-icons';


function ContactUs() {
    const {t} = useTranslation();
    const [from_name, setFromName] = useState('');
    const [from_email, setFromEmail] = useState('');
    const [message, setMessage] = useState('');
    const [formError, setFormError] = useState('');
    const navigate = useNavigate();

    function handleSubmit(e) {
        e.preventDefault();

        if (from_name.trim() === '' || from_email.trim() === '' || message.trim() === '') {
            setFormError(t('contactUs.error.fillAllFields'));
            return;
        }

        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(from_email)) {
            setFormError(t('contactUs.error.invalidEmail'));
            return;
        }

        emailjs.sendForm('service_1dujefp', 'template_zr45v3i', e.target, 'C1JLUN8LahJXEKzYg')
            .then((response) => {
                console.log('E-mail envoyé !', response.status, response.text);
                navigate("/contactUs/success");
            }, (error) => {
                console.error('Erreur lors de l\'envoi de l\'e-mail :', error);
            });
    }

    return (
        <Container className="pt-3">
            <h1 className="p-3 fw-bolder">{t("contactUs.label")}</h1>
            <Row>
                <Col md={7}>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="p-3 h4" controlId="formName">
                            <Form.Label>{t('contactUs.name.label')}</Form.Label>
                            <Form.Control type="text" name="from_name" placeholder={t('contactUs.name.placeholder')} value={from_name}
                                          onChange={(e) => setFromName(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="p-3 h4" controlId="formEmail">
                            <Form.Label>{t('contactUs.email.label')}</Form.Label>
                            <Form.Control type="email" name="from_email" placeholder={t('contactUs.email.placeholder')} value={from_email}
                                          onChange={(e) => setFromEmail(e.target.value)}/>
                        </Form.Group>

                        <Form.Group className="p-3 h4" controlId="formMessage">
                            <Form.Label>{t('contactUs.message.label')}</Form.Label>
                            <Form.Control as="textarea" rows={3} name="message" placeholder={t('contactUs.message.placeholder')}
                                          value={message} onChange={(e) => setMessage(e.target.value)}/>
                        </Form.Group>

                        {formError && <p className="text-danger">{formError}</p>}
                        <Button className="m-3" variant="primary" type="submit">
                            {t('contactUs.button')}
                        </Button>
                    </Form>
                </Col>
                <Col md={5}>
                    <div className="d-flex justify-content-between px-4">
                        <a href="https://www.facebook.com/trucknrollencoeur/?locale=fr_CA" target="_blank"
                           rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebook} size="2x"/></a>
                        <a href="https://www.instagram.com/trucknroll_encoeur/" target="_blank" rel="noreferrer"
                           style={{color: '#b93ab7'}}>
                            <FontAwesomeIcon icon={faInstagram} size="2x"/></a>
                        <a href="https://snapchat.com/t/Gqf9Tdkc" target="_blank" rel="noreferrer"
                           style={{color: '#fffc00'}}>
                            <FontAwesomeIcon icon={faSnapchat} size="2x"/></a>
                        <a href="https://www.tiktok.com/@festi.trucknrollencoeur?_t=8lVXe5f8Tp8&_r=1" target="_blank" rel="noreferrer"
                           style={{color: '#111'}}>
                            <FontAwesomeIcon icon={faTiktok} size="2x"/></a>
                    </div>
                    <p className="p-3 h5">{t("contactUs.more")}</p>
                </Col>
            </Row>
        </Container>
    );
}

export default ContactUs;
