import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";

function SponsorCategorie({ tierName, sponsors }) {
    const imageStyle = {
        width: '200px',
        height: '200px',
        objectFit: 'contain'
    };

    return (
        <Container className="border1 py-3 px-0">
            <h3 className="py-3 ps-4 fw-bold">{tierName}</h3>
            <Row>
                {sponsors.map((sponsor, index) => (
                    <Col key={index} xs={6} sm={6} md={4} lg={3} xl={2}>
                        <a href={sponsor.link} target="_blank" rel="noopener noreferrer">
                            <img src={sponsor.src} alt={sponsor.alt} style={imageStyle}/>
                        </a>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default SponsorCategorie;
