import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

function SuccessMessage() {
    const {t} = useTranslation();
    return (
        <Container>
            <h2 className="p-3 pt-4 fw-bolder">{t("contactUs.success.title")}</h2>
            <p className="p-3 h5">{t("contactUs.success.text")}</p>
            <Button as={Link} to="/" className="btn btn-primary m-3">{t("contactUs.success.btn")}</Button>
        </Container>
    );
}

export default SuccessMessage;
