import Container from "react-bootstrap/Container";
import {Col, Row} from "react-bootstrap";
import CustomImage from "./CustomImage";

function SideBySidePictures({ image1, image2 }) {

    return (
        <Container>
            <Row className="justify-content-center">
                <Col xs={12} md={6} className="text-center mb-3">
                    <CustomImage image={image1} handleOnClick={() => {}} />
                </Col>
                <Col xs={12} md={6} className="text-center mb-3">
                    <CustomImage image={image2} handleOnClick={() => {}} />
                </Col>
            </Row>
        </Container>
    );
}


export default SideBySidePictures;
